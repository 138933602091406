/**
 * https://getbootstrap.com/docs/5.2/customize/optimize/#lean-javascript
**/

import 'bootstrap/js/dist/collapse'; // per tabelle woocommerce

// active state on button click
// https://stackoverflow.com/questions/37334613/vanilla-js-remove-class-from-all-other-elements-besides-active-class
let active_collapse = Array.from(document.querySelectorAll('.collapse_block .collapse_trigger'));    
const handleClick_collapse = (e) => {
  e.preventDefault();
  active_collapse.forEach(node => {
    node.classList.remove('active');
  });
  e.currentTarget.classList.toggle('active');
  
}
active_collapse.forEach(node => {
  node.addEventListener('click', handleClick_collapse)
});

document.querySelectorAll('button[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});